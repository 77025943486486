<template>
  <main-layout>
    <h3>Need more information about Andy and his music? Send an email to </h3>
    <a href="mailto:management@andyfrantz.com" target="_blank">
      management@andyfrantz.com
    </a>
    <h3>Or click on the Track button below! 👇</h3>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "contact",
  components: {
    MainLayout
  }
};
</script>
