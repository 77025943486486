<template>
  <main-layout>
    <!-- TODO: replace 2trPFAz1jzWrgEoXBarIZ with Tequila Tears -->
    <!-- <div>
      <iframe class="_1ru_o" style="width:250px;height:330px" title="Spotify Player" data-src="" scrolling="no" src="https://embed.spotify.com/?uri=spotify%3Aalbum%3A2trPFAz1jzWrgEoXBarIZ&amp;color=black&amp;style=coverart"></iframe>
    </div> -->
    <v-link href="/music"><img alt="Tequila Tears - Andy Frantz" src="../assets/TequilaTearsCover_lowres.jpeg" width="100%" /></v-link>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import VLink from "../components/vlink.vue";

export default {
  name: "app",
  components: {
    MainLayout,
    VLink
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
  font-style: normal;
  text-decoration: none;
}
</style>