// import Schedule from './pages/Schedule.vue';
import Home from './components/Home.vue';
import Contact from './pages/Contact.vue';
// import About from './pages/About.vue';
import Music from './pages/Music.vue';
// import Media from './pages/Media.vue';
// import epk from './pages/epk.vue';

export default {
    '/': {
        name: 'Home',
        view: Home,
    },
    // '/schedule': {
    //     name: 'Schedule',
    //     view: Schedule,
    // },
    '/contact': {
        name: 'Contact',
        view: Contact,
    },
    // '/about': {
    //     name: 'About',
    //     view: About,
    // },
    '/music': {
        name: 'Music',
        view: Music,
    },
    // '/epk': {
    //     name: 'EPK',
    //     view: epk,
    // },
};