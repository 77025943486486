<template>
  <main-layout>
    <h1>Albums</h1>
    <h2>
      Tequila Tears (2021)<br>
      <img alt="Tequila Tears - Andy Frantz" src="../assets/TequilaTearsCover_lowres.jpeg" width="200px" style="margin-top: 0.2em" />
    </h2>
    <div class="media-links">
      <a alt="Spotify" href="https://open.spotify.com/album/1zjTxf9bR2iEwRfydX2bC8" target="_blank">
        <img alt="Spotify - Tequila Tears" src="../assets/Spotify_Icon_RGB_Black.png" width="40px" style="margin-right: 1em" />
      </a>
      <a alt="Apple Music" href="https://music.apple.com/us/album/tequila-tears-ep/1550461686" target="_blank">
        <img alt="Apple Music - Tequila Tears" src="../assets/US-UK_Apple_Music_Listen_on_Lockup_all-blk_100617.svg" width="150px"  style="margin-right: 1em"/>
      </a>
      <a alt="Amazon Music" href="https://music.amazon.com/albums/B08TV8SXF9" target="_blank">
        <img alt="Amazon Music - Tequila Tears" src="../assets/assets.amazonmusic.com.png" width="40px" />
      </a>
    </div>
    <div>
      <iframe class="_1ru_o" style="width:250px;height:250px" title="Spotify Player" data-src="" scrolling="no" src="https://embed.spotify.com/?uri=spotify%3Aalbum%3A1zjTxf9bR2iEwRfydX2bC8&amp;color=black&amp;style=coverart"></iframe>
    </div>
    <div class="music-details">
      <h3>Tracks:</h3>
      <ol class="tracks">
        <li>Barking Dogs</li>
        <li>Cracks in the Drywall</li>
        <li>Tequila Tears</li>
        <li>Scar on My Guitar</li>
        <li>That's All She Wrote</li>
      </ol>
      <h3>Credits:</h3>
      <ul class="credits">
        <li>All songs written by <i>Andy Frantz</i> (BMI)</li>
        <li>Vocals: <i>Andy Frantz</i></li>
        <li>Electric Guitar: <i>Andy Frantz</i></li>
        <li>Acoustic Guitar: <i>Andy Frantz</i></li>
        <li>Bass: <i>Andy Frantz</i></li>
        <li>Baritone Guitar: <i>Andy Frant</i>z</li>
        <li>Pedal Steel: <i>Andy Frantz</i></li>
        <li>Fiddle: <i>Andy Frantz</i></li>
        <li>Produced by: <i>Andy Frantz</i></li>
        <li>Mix Engineer: <i>Andy Frantz</i></li>
        <li>Master Engineer: <i>Andy Frantz</i></li>
        <li>Assistant to the head gaffer: <i>Andy Frantz</i></li>
      </ul>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "music",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
.music-details {
  text-align: justify;
  display: table;
  margin: 0 auto;
}
.music-details h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.music-details ol {
  margin-top: 0.2em;
}
.music-details ul {
  margin-top: 0.2em;
}

.media-links {
  margin-bottom: 10px;
}

.tracks {
  font-size:large;
}

.credits li {
  list-style-type:disc
}
</style>