<template>
  <div id="app">
    <div class="container">
      <div>
        <ul class="header-menu">
          <li>
            <v-link href="/">Home</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/music">Music</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/contact">Contact</v-link>
          </li>
          <!--
          <li>
            <v-link href="/schedule">Schedule</v-link>
          </li>
          <li>&#9734;</li>
          <li>&#9734;</li>
          <li>
            <v-link href="/media">Media</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/about">About</v-link>
          </li>
           -->
        </ul>
        <!-- <h1>Andy Frantz</h1> -->
      </div>
      <slot></slot>
      <div style="margin: 1em 0em 0em 0em;">
      <iframe src="https://www.bandsintown.com/artist/Andy+Frantz/track_button?size=small&display_tracker_count=true&text_color=%23FFFFFF&background_color=%23D0B98F&hover_color=%23945f31" height="25" width="165" scrolling="no" frameborder="0" style="border:none; overflow:hidden;" allowtransparency="true" ></iframe>
      </div>
      <div class="footer">Copyright © 2021 Andy Frantz, All rights reserved</div>
    </div>
  </div>
</template>

<script>
import VLink from "../components/vlink.vue";

export default {
  components: {
    VLink
  }
};
</script>

<style>
#app {
  font-family: Times, serif, sans-serif;
  font-variant: small-caps;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #D0B98F;
  margin-top: 0px;
  background: #945f31;
  min-height: 100vh;
}
</style>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1px 30px;
  background:  #8C4F19;
  min-height: 100vh;
}
.footer {
  margin: 20px;
}
h1 {
  font-size: 4em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

h3 {
  margin: 40px 0 0;
}
.header-menu ul {
  list-style-type: none;
  padding: 0;
}
.header-menu li {
  display: inline-block;
  margin: 0 10px;
}
</style>
